import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Booking } from '../booking';
import { DateBook } from '../dateBook';
import { TimeBook } from '../timeBook';
import { DateTimeBook } from '../dateTimeBook';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {ThemePalette} from '@angular/material/core';
import { User } from '../user';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-my-assessments',
  templateUrl: './my-assessments.component.html',
  styleUrls: ['./my-assessments.component.scss']
})
export class MyAssessmentsComponent implements OnInit {

  constructor(private router : Router, private api : ApiService,public sanitizer: DomSanitizer) { }

  theStuff : any;
  img : any;
  ass : any;

  show = false;
  searchText;
  numList = 0;

  ngOnInit(): void {
    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }
    this.getAssessments();*/
  }

  moveAssess(){
    this.router.navigate(['assessment']);
  }
  getAssessment(oo : any){
    this.api.ImgByAss(oo.AssessmentID).subscribe(res => {
      //this.img = this.sanitizer.bypassSecurityTrustResourceUrl(res[0].AssessmentImage) ;
      this.img = res[0].AssessmentImage;
      this.show =! this.show;
      this.api.GetAssessments2(oo.AssessmentID).subscribe(res => {
        this.ass = res[0];

      });
    });
  
  }

  getAssessments(){
    let u = Number(localStorage.getItem('userId'));
    this.api.GetAssessments(u).subscribe(res => {
      this.theStuff = res;
      this.numList = this.theStuff.length;
    });
  }

}
