import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { User } from '../user';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {

  constructor(private router : Router, private api : ApiService, private _snackBar: MatSnackBar) { }

  _User: User = new User();
  newUser:  any;
  pwdVer : string;

  showLoading = false;
  showNot = true;
  showError = false;
  showError1 = false;

  hide = true;
  hide2 = true;

  openSnackBar() {
    this._snackBar.open('You have created your profile successfully.', 'OK', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      
    });
  }

  showLoadingPage(){
    this.showLoading = !this.showLoading;
    this.showNot = !this.showNot;
  }

  CreateUser(){
    this.router.navigate(['home']);
    /*this.showLoadingPage();
    this._User.PromoID = 1;
    this._User.SpecialID = 1;
    this._User.UserTypeID = 3;
    this._User.UserSession = "";
    

    this.api.NewUser(this._User).subscribe(res => {
      if (res['Error1']) {
        this.showError1 =true;
        this.showLoadingPage();
      }
      else{

      
      if (res['Error']){
        this.showError = true;
        this.showLoadingPage();
      }
      else{

      
      this.openSnackBar();
      this.newUser = res;
      localStorage.setItem('userSession', this.newUser.UserSession);
      this.router.navigate(['home']);
      }
    }
    });*/
  
    
  
  }

  Back2Login(){
    this.router.navigate([""]);
  }

  ngOnInit(): void {
  }

}
