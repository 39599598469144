import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Booking } from '../booking';
import { Assessment } from '../assessment';
import { AssessmentImage } from '../assessmentImage';
import { DateBook } from '../dateBook';
import { TimeBook } from '../timeBook';
import { DateTimeBook } from '../dateTimeBook';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {ThemePalette} from '@angular/material/core';
import { User } from '../user';
import { ToastrService } from 'ngx-toastr';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  constructor(private router : Router, private api : ApiService, private _snackBar: MatSnackBar) { }

  currentUser :  User = new User();
  uu : any;
  showLoading = false;
  showNot = true;

  ngOnInit(): void {
    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }
    this.GetUser();*/
  }

  GetUser(){
    let u = localStorage.getItem('userSession');
    this.api.GetUser(u).subscribe(res => {
      this.uu = res;
      this.currentUser.Email = res['Email'];
      this.currentUser.Phone = res['Phone'];
      this.currentUser.UserID = res['UserID'];

    })
  }

  UpdateUser(){
    this.router.navigate(['home']);
    /*this.openSnackBar();
    this.api.UpdateUser(this.currentUser).subscribe(res => {
      this.router.navigate(['home']);
    })*/
  }

  Back2Home(){
    this.router.navigate(['home']);
  }

  openSnackBar() {
    this._snackBar.open('You have updated your profile successfully.', 'OK', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      
    });
  }




}
