<body>

<div *ngIf="showNot">
    
  
    <div id="logo" style="padding-top: 5%; padding-bottom: 0%;">
          <h1 style="font-size: 60px;">Assessment <mat-icon>description</mat-icon></h1>
    </div>

    <p>Please fill out this form honestly to gaurantee an accurate assessment for you.</p>
    <p>If your answer is <b>Yes</b>, please provide the information.</p>
      
    <div class="target">
      <form (ngSubmit)="CreateAssessment()">
  
        
          
      <div class="form-group">
        <div class="login">
          <h2>Sex:</h2>
          <mat-form-field appearance="outline">
            <mat-label>Sex:</mat-label>
            <mat-select [(ngModel)]="Assessment.GenderID" [ngModelOptions]="{standalone: true}" required> 
                <mat-option *ngFor="let slot of gendersList" value={{slot.GenderID}} required>
                  {{slot.GenderName}}
                </mat-option>
            </mat-select>
          </mat-form-field>          
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Age (yrs):</h2>
          <mat-form-field appearance="outline">
            <mat-label>Age (yrs):</mat-label>
              <input matInput type="number" maxlength="2" [(ngModel)]="Assessment.AssessmentAge" placeholder="Age" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2> Height (m):</h2>
          <mat-form-field appearance="outline">
            <mat-label>Height (m):</mat-label>
              <input matInput type="number" maxlength="5" [(ngModel)]="Assessment.AssessmentHeight" placeholder="Height" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Weight (kg):</h2>
          <mat-form-field appearance="outline">
            <mat-label>Weight (kg):</mat-label>
              <input matInput type="number" maxlength="5" [(ngModel)]="Assessment.AssessmentWeight" placeholder="Current Weight" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Target weight (kg):</h2>
          <mat-form-field appearance="outline">
            <mat-label>Target weight (kg):</mat-label>
              <input matInput type="number" [(ngModel)]="Assessment.AssessmentTargetWeight" placeholder="Goal Weight" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Medical conditions?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Medical conditions?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentMedical" placeholder="Medical Conditions" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Goals for the next 8 weeks?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Goals for the next 8 weeks?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentGoal" placeholder="Goals" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Food allergies/dislikes?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Food allergies/dislikes?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentAllergies" placeholder="Food Dislike / Allergies" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>


      <div class="form-group">
        <div class="login">
          <h2>Favourite healthy foods?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Favourite healthy foods?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentHealthy" placeholder="Healthy Foods" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Daily basis foods?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Daily basis foods?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentDaily" placeholder="Daily Foods" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Physical injuries?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Physical injuries?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentInjury" placeholder="Injuries Recently?" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Training history?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Training history?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentTraining" placeholder="Training History" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Training flexibility for availability?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Training flexibility for availability?</mat-label>
              <input matInput type="text" [(ngModel)]="Assessment.AssessmentFlexibility" placeholder="Training Flexibility" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Average hours of sleep?</h2>
          <mat-form-field appearance="outline">
            <mat-label>Average hours of sleep?</mat-label>
              <input matInput type="number" maxlength="4" [(ngModel)]="Assessment.AssessmentSleep" placeholder="Hours of Sleep" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group">
        <div class="login">
            <h2>Upload a photo of your full body to benchmark your performance: (.png .jpg only)</h2>
              <input id="img" type="file" (change)="onFileSelected($event)" [(ngModel)]="AssessmentImage.AssessmentImage" class="form-control" [ngModelOptions]="{standalone: true}" required>
        </div>
      </div>
      <!--
-->
        <div *ngIf="showError" style="background-color: red; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
          <h2><mat-icon>error</mat-icon>        Please enter the correct assessment information.</h2>
        </div> 
    
        <div class="login">
          <button type="submit"  mat-raised-button  ><mat-icon>description</mat-icon>     Create Assessment</button>&nbsp;
        </div>

      </form>

      <div class="login">
        <button   mat-raised-button (click)="Back2Home()"  >Back</button>
      </div>
    
    </div>
  </div>
  <div *ngIf="showLoading" style="margin-top: 10%;">
    <h1 style="font-size: 60px;">Loading</h1>
      <mat-spinner id="spinz" ></mat-spinner>
  </div>
 
</body>
