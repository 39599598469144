<body style="margin : 0px; padding: 0px;">
<div *ngIf="showNot">
    <div id="logo">
        <img src="assets/img1.jpg" width="350px" height="350px">
      </div>
      
      <div id="logo">
          <h1 style="font-size: 40px;">Create Profile   <mat-icon>how_to_reg</mat-icon></h1>
      </div>

      <p>Enter your personal details in order to create your new profile.</p>
      <p>Select Create after entering your information.</p>

      <div  style="border-style: solid; border-color:  rgb(27, 161, 1);width: 70%; border-radius: 5px; background-color: rgba(7, 27, 0, 0.2); margin: auto;">
        <form #addUserForm="ngForm" (ngSubmit)="CreateUser()">
            
        <div class="form-group">
          <div class="login">
            <h2>Username:</h2>
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput [(ngModel)]="_User.Username" placeholder="Enter your username." class="form-control" [ngModelOptions]="{standalone: true}" required>
            </mat-form-field>
          </div>
        </div>
        
        <div class="form-group">
          <div class="login">
            <h2>Password ( encryption support <mat-icon>lock</mat-icon>):</h2>
            <div >
              <h3>Password must contain the following:</h3>
              <p >A <b>lowercase</b> letter</p>
              <p >A <b>capital (uppercase)</b> letter</p>
              <p >A <b>number</b></p>
              <p >Minimum <b>8 characters</b></p>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
                <input matInput [(ngModel)]="_User.Password"  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [type]="hide ? 'password' : 'text'" placeholder="Enter your password" class="form-control" [ngModelOptions]="{standalone: true}" required>
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
          </div>
        </div>

        <div class="form-group">
            <div class="login">
              <h2>Re-enter the password:</h2>
              <mat-form-field appearance="outline">
                <mat-label>Re-enter Password</mat-label>
                  <input matInput [(ngModel)]="pwdVer" [type]="hide2 ? 'password' : 'text'" placeholder="Enter your password" class="form-control" [ngModelOptions]="{standalone: true}" required>
                  <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                  <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
              </mat-form-field>
            </div>
          </div>

        <div class="form-group">
            <div class="login">
              <h2>Email:</h2>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                  <input matInput type="email"  [(ngModel)]="_User.Email" placeholder="Enter your email" class="form-control" [ngModelOptions]="{standalone: true}" required>
              </mat-form-field>
            </div>
          </div>

          <div class="form-group">
            <div class="login">
              <h2>Phone (min length is 11 chars; add locale eg. RSA = +27):</h2>
              +
              <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                  <input matInput type="number" max="9999999999" min="1" minlength="11" [(ngModel)]="_User.Phone" placeholder="Enter your phone" class="form-control" [ngModelOptions]="{standalone: true}" required>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="showError" style="background-color: red; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
            <h2><mat-icon>error</mat-icon>        Please enter a valid profile information. Be certain that every field is filled correctly. </h2>
          </div>
          <div *ngIf="showError1" style="background-color: red; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
            <h2><mat-icon>error</mat-icon>        Please enter a different username, this one has been taken. </h2>
          </div>
          <div class="login">
            <button type="submit"  mat-raised-button ><mat-icon>how_to_reg</mat-icon>     Create </button>
          </div>
        </form>
      
        <div class="login">
            <button   mat-raised-button (click)="Back2Login()"  >Back</button>
          </div>
        </div>
      </div>

      <br/>
  <br/>
  <br/>
  
<div *ngIf="showLoading" style="padding-top: 10%;">
  <h1 style="font-size: 60px;">Loading</h1>
    <mat-spinner id="spinz" ></mat-spinner>
</div>

<br/>
  <br/>
  <br/>

</body>
