import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(private router : Router, private api : ApiService) { }

  text = "User";
  currentId;
  show = false;

  ngOnInit(): void {
    //let u = localStorage.getItem('userId');
    //this.currentId = u;
    /*if(Number(localStorage.getItem('userId')) == 1){
      this.Management();
      this.show = true;

    }
    else{
      this.Management();
      this.show = false;
    }
  
    
    window.setTimeout(this.LogOut, 120000);*/
    
  }

  MoveHome(){
    this.router.navigate([""]);
  }

  myAss(){
    this.router.navigate(["myAssessment"]);
  }

  myBook(){
    this.router.navigate(["myBooking"]);
  }

  myPro(){
    this.router.navigate(["myProfile"]);
  }

  myBasket(){
    this.router.navigate(["basket"]);
  }
  
  refresh(){
    window.location.reload();
  }

  LogOut(){
    localStorage.removeItem('userSession');
    localStorage.removeItem('bookingType');
    localStorage.removeItem('userId');
    this.router.navigate([""]);
  }

  Management(){
    this.api.GetUser(localStorage.getItem('userSession')).subscribe(res => {
      this.text = res['Username'];
    });
  }

  

}
