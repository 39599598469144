<body>

        <div id="logo" >
         <h1>My Assessments    <mat-icon>description</mat-icon></h1>
        </div>

        <p>This is your <b>Assessments Page</b>. </p>
        <p>Feel free to browse your assessments.</p>

        

        <div class="form-group">
            <div class="login">
                <p style="font-size: 30px;">Search</p>
              <mat-form-field style="width: 80%;" appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
              </mat-form-field>
            </div>
          </div>

            <button (click)="moveAssess()" type="button" style=" width: 80%;  margin: 20px;" mat-raised-button  ><mat-icon>add_circle</mat-icon> New Assessment</button>


        <div id="tableDisplay">
            <table >
                <tr>
                    
                    <th  > Goals </th>
                    <th  > Date Created </th>
                    
                    <th> Action </th>

                </tr>
                <tr>
                    
                    <td> Tone muscles</td>
                    <td> 11/05/2024 </td>
                    <td>    <button mat-icon-button aria-labelledby="View image" class="deleteIcon"><mat-icon  >visibility</mat-icon></button></td>

                </tr>
                <tr>
                    
                    <td> Lose weight</td>
                    <td> 12/05/2024 </td>
                    <td>    <button mat-icon-button aria-labelledby="View image" class="deleteIcon"><mat-icon  >visibility</mat-icon></button></td>

                </tr>
                <tr>
                    
                    <td> Build cardio</td>
                    <td> 13/05/2024 </td>
                    <td>    <button mat-icon-button aria-labelledby="View image" class="deleteIcon"><mat-icon  >visibility</mat-icon></button></td>

                </tr>
                <!--<tr *ngIf="numList == 0">
                    <td colspan="5">No data to display.</td>
                </tr>   
                <tr *ngFor="let element of theStuff | filter:searchText">
                    <td  > {{element.Goal}} </td>
                    <td > {{element.AssessmentDateCreated}}</td>
                   
                    <td>    <button mat-icon-button aria-labelledby="View image" class="deleteIcon" (click)="getAssessment(element)" ><mat-icon  >visibility</mat-icon></button></td>
                    
                    

                </tr>-->
            </table>
        </div>
        <!--<div class="loginGroup" *ngIf="show">
            <h2><u>Image:</u></h2>
            <img id="disImg"  [src]="sanitizer.bypassSecurityTrustUrl(img)" alt="imageShow" >
    
            <h2><u>Age:</u></h2>
            <p>{{ass.AssessmentAge}} yrs</p>
    
            <h2><u>Height:</u></h2>
            <p>{{ass.AssessmentHeight}} m</p>
    
            <h2><u>Current Weight:</u></h2>
            <p>{{ass.AssessmentWeight}} kg</p>
    
            <h2><u>Target weight</u></h2>
            <p>{{ass.AssessmentTargetWeight}} kg</p>
    
            <h2><u>Allergies / Food dislikes:</u></h2>
            <p>{{ass.AssessmentAllergies}}</p>
    
            <h2><u>Healthy favourite foods:</u></h2>
            <p>{{ass.AssessmentHealthy}}</p>
    
            <h2><u>Daily foods:</u></h2>
            <p>{{ass.AssessmentDaily}}</p>
    
            <h2><u>Injuries:</u></h2>
            <p>{{ass.AssessmentInjury}}</p>
    
            <h2><u>Medical conditions:</u></h2>
            <p>{{ass.AssessmentMedical}}</p>
    
            <h2><u>Hours of sleep (avg.):</u></h2>
            <p>{{ass.AssessmentSleep}} hr(s)</p>
    
            <h2><u>Flexibility in scheduling:</u></h2>
            <p>{{ass.AssessmentFlexibility}}</p>
    
            <h2><u>Training history:</u></h2>
            <p>{{ass.AssessmentTraining}}</p>
    
            <h2><u>Contact information</u></h2>
            <p>{{ass.Contact}}</p>
        </div>-->

</body>