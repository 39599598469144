import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Booking } from '../booking';
import { Assessment } from '../assessment';
import { AssessmentImage } from '../assessmentImage';
import { DateBook } from '../dateBook';
import { TimeBook } from '../timeBook';
import { DateTimeBook } from '../dateTimeBook';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {ThemePalette} from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {

  constructor(private router : Router, private api : ApiService,private _snackBar: MatSnackBar) { }

  gendersList : any;
  
  Assessment: Assessment = new Assessment();
  AssessmentImage : AssessmentImage = new AssessmentImage();
  img : File;
  reader = new FileReader(); 

  timeId : any;
  dateId : any;
  dateTimeId : any;

  good2go : boolean;
  good2go1 : boolean;

  _dateBook : DateBook = new DateBook();
  _timeBook : TimeBook = new TimeBook();
  _dateTimeBook : DateTimeBook = new DateTimeBook();

  showLoading = false;
  showNot = true;
  showError = false;
  numList = 0;

  ngOnInit(): void {
    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }
    this.getGenders();*/
  }

  onFileSelected(event){
    this.img = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.img);
     reader.onloadend = () => {
       this.AssessmentImage.AssessmentImage = String(reader.result);
     }
  }

  DoTheDate(){
    
    const theHoursNow = new Date().getHours();
    const theMinutesNow = new Date().getMinutes();
    const theSecondsNow = new Date().getSeconds();
    const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
    const getMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const getDate = new Date().getDate();
    const theDateNow = getDate + "/" + (getMonth + 1) + "/" + currentYear;

  
    this.api.MakeDateTime(this._dateTimeBook).subscribe(res =>{
      this.dateTimeId = res['DateTimeID'];
      this.Assessment.DateTimeID = this.dateTimeId;
      this.Assessment.AssessmentDateCreated = theDateNow + " " + theTimeNow; 
      this.Assessment.UserID = Number(localStorage.getItem('userId'));

      const fd = new FormData();
    
      this.showLoadingPage();
      this.api.MakeAssessment(this.Assessment).subscribe(res => {
      this.showLoadingPage();
      if (res['Error']){
        this.showError = true;
        this.showLoadingPage();
      }
      else{
        this.openSnackBar();
        this.AssessmentImage.AssessmentID = res['AssessmentID'];
        this.api.SendImage(this.AssessmentImage).subscribe(res => {
      });
      this.router.navigate(['myAssessment']);
    }
    }); 
    });
}

showLoadingPage(){
  this.showLoading = !this.showLoading;
  this.showNot = !this.showNot;
}

  CreateAssessment(){

      this.showLoadingPage();
      const fullYear = new Date().getFullYear();
      const getDay = new Date().getDay();
      const getMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      const getDate = new Date().getDate();
      const theDateNow = getDate + "/" + (getMonth + 1) + "/" + currentYear;
      const theHoursNow = new Date().getHours();
      const theMinutesNow = new Date().getMinutes();
      const theSecondsNow = new Date().getSeconds();
      const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
      this._dateBook.DateName = theDateNow;

      this.api.MakeDate(this._dateBook).subscribe(res =>{
        this.dateId = res['DateID'];
        this._dateTimeBook.DateID = this.dateId;
        this.good2go1 = true;
          this._timeBook.TimeName = theTimeNow;
          this.api.MakeTime(this._timeBook).subscribe(res =>{
          this.timeId = res['TimeID'];
          this._dateTimeBook.TimeID = this.timeId;
          this.good2go = true;
          this.DoTheDate();
        });
      });
  }

  openSnackBar() {
    this._snackBar.open('You have made your assessment successfully.', 'OK', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      
    });
  }

  Back2Home(){
    this.router.navigate(['home']);
  }

  getGenders(){
    this.api.GetGenders().subscribe(res => {
      this.gendersList = res;
    });
  }

}
