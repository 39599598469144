<body>

<div *ngIf="showNot">
    <div id="logo" style="padding-top: 3%; padding-bottom: 0%;">
          <h1 style="font-size: 60px;">Booking <mat-icon>book_online</mat-icon></h1>
    </div>

    <p>Choose the selection type, then proceed to select the date(s) of your desire. </p>
    <p>Select the time slot for the day(s)</p>

    <div id="diss">
      <p style="font-size: 20px">Selection Type:</p>
       <mat-button-toggle-group>
        <mat-button-toggle value="single" (click)="Show()">Single</mat-button-toggle>
        <mat-button-toggle value="multiple" (click)="Show1()">Multiple</mat-button-toggle>
       </mat-button-toggle-group>
    </div>
   
  <p style="font-size: 20px; text-align: center;">{{selType}}</p>
    
      
    <div class="target" *ngIf="show">
      <form (ngSubmit)="CreateBooking1()">
  
        
          
      <div class="form-group">
        <div class="login">
          <h2>Date</h2>
          <mat-form-field appearance="outline">
            <mat-label>Select the date:</mat-label>
            <input matInput disabled [(ngModel)]="theDate" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" [ngModelOptions]="{standalone: true}" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi disabled="false" #picker>
              <mat-datepicker-actions>
              <button class="inBtn" mat-button matDatepickerCancel>Cancel</button>
              <button class="inBtn"  mat-raised-button color="primary" matDatepickerApply>Apply</button>
              </mat-datepicker-actions>
            </mat-datepicker>
          </mat-form-field>          
        </div>
      </div>

      <div class="form-group">
        <div class="login">
          <h2>Time</h2>
          <mat-form-field appearance="outline">
            <mat-label>Select the time:</mat-label>
              <mat-select [(ngModel)]="selSlot1" [ngModelOptions]="{standalone: true}" required> 
                  <mat-option *ngFor="let slot of slotList" value={{slot.BookingSlotID}} required>
                    {{slot.BookingSlot}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="showError" style="background-color: red; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
        <h2><mat-icon>error</mat-icon>     Please enter the correct date and time slot.</h2>
      </div> 
    
        <div class="login">
          <button type="submit"  mat-raised-button  ><mat-icon>book_online</mat-icon>Create Booking</button>&nbsp;
        </div>
      </form>


      <div class="login">
        <button   mat-raised-button (click)="Back2Home()"  >Back</button>
      </div>

      <button *ngIf="showP" (click)="ApplyPromo()" type="button"  mat-raised-button  ><mat-icon>redeem</mat-icon> Redeem Free Session</button>&nbsp;&nbsp;
      <br>
    </div>

    <div class="target" *ngIf="show1">
      <form (ngSubmit)="CreateBooking()">
  
        
          
      <div class="form-group">
        <div class="login">
          <h2>Date range</h2>
          <mat-form-field appearance="outline">
            <mat-label>Select a date range:</mat-label>
            <mat-date-range-input [min]="minDate" [max]="maxDate"  [rangePicker]="picker" required>
              <input matStartDate [(ngModel)]="begDate" [ngModelOptions]="{standalone: true}"  placeholder="Start Date" disabled>
              <input matEndDate (click)="show2" [(ngModel)]="endDate" [ngModelOptions]="{standalone: true}" placeholder="End Date" disabled>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
             
            <mat-date-range-picker touchUi disabled="false" #picker>
            <mat-date-range-picker-actions>
              <button class="inBtn" mat-button matDateRangePickerCancel>Cancel</button>
              <button class="inBtn" (click)="Show2()" mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
            </mat-date-range-picker-actions>
            </mat-date-range-picker>
            </mat-form-field>
        </div>
      </div>

      

      <div *ngIf="show2" class="form-group">
        <div  *ngFor="let yy of dateList; let i = index">
            <mat-form-field appearance="outline">
            <mat-label>Time for day {{yy}}:</mat-label>
              <mat-select [(ngModel)]="selSlotPerDate[yy]" [ngModelOptions]="{standalone: true}" required> 
                  <mat-option *ngFor="let slot of slotList" value={{slot.BookingSlotID}}  required>
                    {{slot.BookingSlot}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
        </div>   
      </div>

      <div *ngIf="showError" style="background-color: red; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
        <h2><mat-icon>error</mat-icon>     Please enter the correct date and time slot.</h2>
      </div> 
    
        <div class="login">
          <button type="submit"  mat-raised-button  ><mat-icon>book_online</mat-icon>Create Booking</button>&nbsp;
        </div>
      </form>


      <div class="login">
        <button   mat-raised-button (click)="Back2Home()"  >Back</button>
      </div>

      <button *ngIf="showP" (click)="ApplyPromo()" type="button"  mat-raised-button  ><mat-icon>redeem</mat-icon> Redeem Free Session</button>&nbsp;&nbsp;
      <br>
    </div>

    <div *ngIf="showZoom" style="background-color: blue; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
      <h2><mat-icon>verified</mat-icon> We will contact you through E-mail or Phone regarding your <b>Zoom Session</b>right after you book it.</h2>
    </div> 
    
    <div *ngIf="showAss" style="background-color: blue; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
      <h2><mat-icon>verified</mat-icon> We will contact you  via E-mail or Phone regarding your personalised nutrition & health feedback right after you get assessed.</h2>
    </div> 
</div>

<div *ngIf="showLoading" style="margin-top: 10%;">
  <h1 style="font-size: 60px;">Loading</h1>
    <mat-spinner id="spinz" ></mat-spinner>
</div>

<div *ngIf="showB">
    
  <div id="logo" >
    <h1>Bookings Checkout    <mat-icon>shopping_cart</mat-icon></h1>
   </div>

   <p>This is where you <b>Checkout your Basket </b><mat-icon>shopping_cart</mat-icon>. </p>
   <p>You can proceed to finalise your booking by selecting proceed.</p>
   




   <div id="tableDisplay" >
   <table class="mat-elevation-z8">
       <tr>
           
           
           
           <th  > Description </th>
       
           <th> Date Booked</th>
           <th > Amount (incl. VAT)</th>
       </tr>
       <tr  *ngFor="let element of theStuff ">

           <td  > {{element.BookingDescription}} </td>
           <td  > {{element.BookingCreated}} </td>
           <td style="text-align: right; margin-right:10px;">    R40,00 </td>


       </tr>
       <tr>
           
           <td></td>
           <td></td>
           <td><b>R{{total}},00</b></td>

       </tr>
   </table>



</div>

<button (click)="Proceed()" type="button" style=" width: 80%;  margin: 20px;" mat-raised-button  ><mat-icon>add_shopping_cart</mat-icon> Proceed</button>&nbsp;&nbsp;
<button (click)="moveAssess1()" type="button" style=" width: 80%;  margin: 20px;" mat-raised-button  >Back</button>


</div>

</body>
