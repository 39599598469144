<body style="width: 100%;">


        <div id="logo" >
         <h1>My Bookings   <mat-icon>date_range</mat-icon></h1>
        </div>

        <p>This is your <b>Bookings Homepage</b>. </p>
        <p>You can cancel your booking by cliking the <mat-icon>delete_forever</mat-icon> icon.</p>
        <p>There various tasks you can perform here. Fell free to browse your bookings.</p>

        
              
    <div class="form-group">
        <div class="login">
            <p style="font-size: 30px;">Search</p>
          <mat-form-field style="width: 80%;" appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
          </mat-form-field>
        </div>
      </div>

      <button (click)="moveAssess()" type="button" style=" width: 80%;  margin: 20px;" mat-raised-button  ><mat-icon>add_circle</mat-icon> New Booking</button>


      <!--class="mat-elevation-z8"-->
      <div id="tableDisplay">
        <table>
            <tr>
                <th  > Type </th>
                <th  > Slot </th>
                <th  > Status </th>
            
                <th> Date Booked</th>
                <th > Actions</th>
            </tr>

            <tr>
              <td> HIIT Cardio Training</td>
              <td> 11:00 - 12:00 </td>
              <td> Booked </td>
              <td> 11/05/2024</td>
              <td> <button mat-icon-button class="deleteIcon"><mat-icon>delete_forever</mat-icon></button></td>
            </tr>

            <tr>
              <td> Private Training</td>
              <td> 12:00 - 13:00 </td>
              <td> Booked </td>
              <td> 12/05/2024</td>
              <td> <button mat-icon-button class="deleteIcon"><mat-icon>delete_forever</mat-icon></button></td>
            </tr>
             
            <tr>
              <td> Yoga & Pilates</td>
              <td> 13:00 - 14:00 </td>
              <td> Pending </td>
              <td> 13/05/2024</td>
              <td> <button mat-icon-button class="deleteIcon"><mat-icon>delete_forever</mat-icon></button></td>
            </tr>

            <!--<tr  *ngFor="let element of theStuff | filter:searchText">
                
                

                <td > {{element.BookingTypeName}}</td>
                <td  > {{element.BookingSlotName}} </td>
                <td  > {{element.BookingStatusName}} </td>
                <td  > {{element.Date}} </td>
                <td> <button mat-icon-button class="deleteIcon" (click)="cancelBooking(element)" ><mat-icon>delete_forever</mat-icon></button></td>

            </tr>-->
        </table>
      </div>

</body>