import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Booking } from '../booking';
import { DateBook } from '../dateBook';
import { TimeBook } from '../timeBook';
import { DateTimeBook } from '../dateTimeBook';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {ThemePalette} from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  constructor(private router : Router, private api : ApiService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }

    if(localStorage.getItem('bookingType') == '8'){
      this.showZoom = true;
    }

    if(localStorage.getItem('bookingType') == '6'){
      this.showAss = true;
    }


    this.good2go = false;
    this.good2go1 = false;
    this.getUsers();
    this.getTheSlots();
   

 
    
    const fullYear = new Date().getFullYear();
    const getDay = new Date().getDay();
    const getMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const getDate = new Date().getDate();

    this.minDate = new Date(currentYear +0, getMonth, getDate);
    this.maxDate = new Date(fullYear + 1, 0, 0);

    this.todayDate = getDate + "/" + (getMonth +1) + "/" + currentYear;*/

  
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  minDate: Date;
  maxDate: Date;

  todayDate : any;
  
  theStuff;
  _Booking: Booking = new Booking();
  _dateBook : DateBook = new DateBook();
  _timeBook : TimeBook = new TimeBook();
  _dateTimeBook : DateTimeBook = new DateTimeBook();


  slots : any;
  show = true;
  show1 = false;
  show2 = false;
  show3 = false;
  show4 = false;
  showLoading = false;
  showNot = true;

  showB = false;
  total = 0;
  showP = false;

  theDate : any;
  theDate1 : any;
  theDate2 : any;

  begDate : Date;
  begDateNow : any;
  endDate : Date;
  endDateNow : any;
  showError= false;
  showZoom = false;
  showAss = false;

  currentUser : any;

  selSlotPerDate = new Array();

  postDate : any;
  selSlot1 : any;
  selSLot2 : any;
  selSlot3 : any;

  dateList = new Array();
  selType = "Single";
  slotList : any;

  timeId : any;
  dateId : any;
  dateTimeId : any;

  good2go : boolean;
  good2go1 : boolean;

  basket = new Array();

  moveAssess(){
    this.router.navigate(['myBookings']);
  }
 
  
  moveAssess1(){
     window.history.back();
 }
  

  Show(){
    this.show = true;
    this.show1 = false;

    if(this.selType == "Multiple"){
      this.selType = "Single";
    }
  
  }

  Show1(){
    this.show = false;
    this.show1 = true;

    if(this.selType == "Single"){
      this.selType = "Multiple";
    }
    
  }

  Show2(){
    if(this.begDate != null && this.endDate != null){
      this.show2 = true;
    }
    else{
      this.show2 = false;
    }

  
    //const newDate = new DatePipe('en-US').transform(this.theDate, 'dd/MM/yyyy');
    const newDate1 = new DatePipe('en-US').transform(this.theDate1, 'dd/MM/yyyy');
    const newDate2 = new DatePipe('en-US').transform(this.begDate, 'dd/MM/yyyy');
    const newDate3 = new DatePipe('en-US').transform(this.endDate, 'dd/MM/yyyy');


    

     this.begDateNow = this.begDate.getDate();
     this.endDateNow = this.endDate.getDate();

  
    this.dateList.length = 0;
    if (this.endDateNow < this.begDateNow){
      for (let ii = this.begDateNow; 32 > ii; ii++ ){
        const thisYear = this.begDate.getFullYear();
        const thisYearMonth = this.begDate.getMonth();
        
        this.dateList.push(ii);
        if (ii == 31){
          for(let jj = 1; this.endDateNow +1 > jj; jj++){
            this.dateList.push(jj);
          }
        }
      }
      
    }
    else{
        for (let index = this.begDateNow; this.endDateNow +1 > index; index++) {
        const thisYear = this.begDate.getFullYear();
        const thisYearMonth = this.begDate.getMonth();
        
        this.dateList.push(index);

            
      }
    }
    

    

  }

  showLoadingPage(){
    this.showLoading = !this.showLoading;
    this.showNot = false;
  }

  showBasket(){
    this.showB =! this.showB;
    this.showNot =! this.showNot;
}

  ApplyPromo(){
    localStorage.setItem('promo', '1');
    this.currentUser.PromoID = 2;
    this.total = this.total - 20;
    this.showP = false;
  }

  getUsers(){
    let x = localStorage.getItem('userSession');
    this.api.GetUser(x).subscribe(res => {
      
      this.currentUser = res;
      console.log('Id:', this.currentUser.UserID);
      if(res['PromoID'] == 1){
        this.showP = true;
      }
      else{
        this.showP = false;
      }
      
    });
  }

  getTheSlots(){
    this.api.GetSlots().subscribe( res => {
      
      this.slotList = res;
    });
  }

  Proceed(){
    this.showLoadingPage();
    this.router.navigate(['myBooking']);
  }

  theSlotsList = [
     {"BookingSlotID": "1", "BookingSlot": "05:30 - 06:30"},
     {"BookingSlotID": "2","BookingSlot": "06:30 - 07:30"},    
     {"BookingSlotID": "3","BookingSlot": "07:30 - 08:30"},
     {"BookingSlotID": "4","BookingSlot": "08:30 - 09:30"},

]

  slotsDrop(){
    this.api.GetSlots().subscribe(res => {
    
      this.slots = res;
    });
  }

  Back2Home(){
    this.router.navigate(['home']);
  }

  openSnackBar() {
    this._snackBar.open('You have made your booking successfully.', 'OK', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      
    });
  }

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()  
  })

  CreateBooking(){

    this.router.navigate(['basket']);
   
   /*this.showBasket();
   // this.showLoadingPage();
   //const begDate = this.begDate.getDate();
   //const endDate = this.endDate.getDate();

   const fullYear = new Date().getFullYear();
    const getDay = new Date().getDay();
    const getMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const getDate = new Date().getDate();

    const theHoursNow = new Date().getHours();
    const theMinutesNow = new Date().getMinutes();
    const theSecondsNow = new Date().getSeconds();

    const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;


   for (let index = this.begDateNow; 32 > index; index++) {
    const theHoursNow = new Date().getHours();
    const theMinutesNow = new Date().getMinutes();
    const theSecondsNow = new Date().getSeconds();

    const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
    const thisYear = this.begDate.getFullYear();
    const thisYearMonth = this.begDate.getMonth();

    
    this.postDate = index + "/" + (getMonth +1) + "/" + currentYear;
    

        
      this._dateBook.DateName = this.postDate;
      this.api.MakeDate(this._dateBook).subscribe(res =>{
        
        this.dateId = res['DateID'];
        this._dateTimeBook.DateID = this.dateId;
        this.good2go1 = true;
        

      this._timeBook.TimeName = theTimeNow;
      this.api.MakeTime(this._timeBook).subscribe(res =>{
       
        this.timeId = res['TimeID'];
        this._dateTimeBook.TimeID = this.timeId;
        this.good2go = true;
        
        //this.DoTheDate();
        const theHoursNow = new Date().getHours();
        const theMinutesNow = new Date().getMinutes();
        const theSecondsNow = new Date().getSeconds();
    
        
        this.api.MakeDateTime(this._dateTimeBook).subscribe(res =>{
          
          this.dateTimeId = res['DateTimeID'];
          this._Booking.DateTimeID = this.dateTimeId;
          
          
          this._Booking.UserID = this.currentUser.UserID;
          this._Booking.BookingDescription = this.currentUser.Username + " booked this session.";
          this._Booking.BookingSlotID = this.selSlotPerDate[index];
          this._Booking.BookingStatusID = 1;
          
          this._Booking.BookingTypeID = Number(localStorage.getItem('bookingType'));
          this._Booking.BookingCreated = this.todayDate + "; " + theTimeNow;

          

          
         
              this.api.MakeBooking(this._Booking).subscribe(res => {
              
              if (res['Error']){
                this.showError = true;
                this.showLoadingPage();
              }
              else{
                this.openSnackBar();
        
              
              this.basket.push(res);
              this.theStuff = this.basket;
              this.total = (this.theStuff.length * 40);
              this.api.UpdateUser(this.currentUser).subscribe(res => {
                
              });
              if(this.theStuff.length >= 3){
                this.total = this.total - 40;
                //this.currentUser.PromoID = 1;
              }
              this.openSnackBar();
              this.api.CourierData(res['BookingID']).subscribe(res =>{
               
                //this.router.navigate(['basket']);
               

              });
            }
            });
          

        });
        
      });

      });

     
      

      if (index == 31){
        for(let jj = 1; this.endDateNow +1 > jj; jj++){
         
          const theHoursNow = new Date().getHours();
          const theMinutesNow = new Date().getMinutes();
          const theSecondsNow = new Date().getSeconds();
      
          const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
          const thisYear = this.begDate.getFullYear();
          const thisYearMonth = this.begDate.getMonth();
      
          
          this.postDate = jj + "/" + (getMonth +1) + "/" + currentYear;
          
      
              
            this._dateBook.DateName = this.postDate;
            this.api.MakeDate(this._dateBook).subscribe(res =>{
              
              this.dateId = res['DateID'];
              this._dateTimeBook.DateID = this.dateId;
              this.good2go1 = true;
           
      
            this._timeBook.TimeName = theTimeNow;
            this.api.MakeTime(this._timeBook).subscribe(res =>{
            
              this.timeId = res['TimeID'];
              this._dateTimeBook.TimeID = this.timeId;
              this.good2go = true;
              
              //this.DoTheDate();
              const theHoursNow = new Date().getHours();
              const theMinutesNow = new Date().getMinutes();
              const theSecondsNow = new Date().getSeconds();
          
              //const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
          
              
        
              this.api.MakeDateTime(this._dateTimeBook).subscribe(res =>{
                
                this.dateTimeId = res['DateTimeID'];
                this._Booking.DateTimeID = this.dateTimeId;
                
                
                this._Booking.UserID = this.currentUser.UserID;
                this._Booking.BookingDescription = this.currentUser.Username + " booked this session.";
                this._Booking.BookingSlotID = this.selSlotPerDate[jj];
                this._Booking.BookingStatusID = 1;
                
                this._Booking.BookingTypeID = Number(localStorage.getItem('bookingType'));
                this._Booking.BookingCreated = this.todayDate + "; " + theTimeNow;
      
               
                
               
                    this.api.MakeBooking(this._Booking).subscribe(res => {
                  
                    if (res['Error']){
                      this.showError = true;
                      this.showLoadingPage();
                    }
                    else{
                      this.openSnackBar();
              
                    
                    this.basket.push(res);
                    this.theStuff = this.basket;
                    this.total = (this.theStuff.length * 20);
                    this.api.UpdateUser(this.currentUser).subscribe(res => {
                      
                    });
                    if(this.theStuff.length >= 3){
                      this.total = this.total - 20;
                      //this.currentUser.PromoID = 1;
                    }
                    this.openSnackBar();
                    this.api.CourierData(res['BookingID']).subscribe(res =>{
                    
                      //this.router.navigate(['basket']);
                     
      
                    });
                  }
                  });
                
      
              });
              
            });
      
            });

        }
      }
    }*/
  }

  SendEmail(){
    
  }

  DoTheBooking(){
    const theHoursNow = new Date().getHours();
    const theMinutesNow = new Date().getMinutes();
    const theSecondsNow = new Date().getSeconds();
   
    

    const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
    this._Booking.UserID = this.currentUser.UserID;
      this._Booking.BookingDescription = this.currentUser.Username + " booked this session.";
      this._Booking.BookingSlotID = Number(this.selSlot1);
      this._Booking.BookingStatusID = 1;
      this._Booking.DateTimeID = this.dateTimeId;
      this._Booking.BookingTypeID = Number(localStorage.getItem('bookingType'));
      this._Booking.BookingCreated = this.todayDate + "; " + theTimeNow;


      this.api.MakeBooking(this._Booking).subscribe(res => {
        
        if (res['Error']){
          this.showError = true;
        }
        else{
          this.openSnackBar();
              this.showBasket();

          this.basket.push(res);
        this.theStuff = this.basket;
    this.total = this.total + (this.theStuff.length * 20);
  
    this.api.UpdateUser(this.currentUser).subscribe(res => {
      
      this.showLoadingPage();

    });
        this.api.CourierData(res['BookingID']).subscribe(res => {
           
          this.openSnackBar();
    
          //this.toastr.success('You have made a booking successfully.', 'Booking Made');
          //this.router.navigate(['basket']);
        });
        }
        
       
      });

  }

  DoTheDate(){
      const theHoursNow = new Date().getHours();
      const theMinutesNow = new Date().getMinutes();
      const theSecondsNow = new Date().getSeconds();
  
      const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
  
     

      this.api.MakeDateTime(this._dateTimeBook).subscribe(res =>{
        
        this.dateTimeId = res['DateTimeID'];
        this._Booking.DateTimeID = this.dateTimeId;
        
        this.DoTheBooking();
      });

    
  }

  CreateBooking1(){

    this.router.navigate(['basket']);    

      //this.showLoadingPage();

     //const begDate = this.begDate.getDate();
     //const endDate = this.endDate.getDate();
  
      /*const fullYear = new Date().getFullYear();
      const getDay = new Date().getDay();
      const getMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      const getDate = new Date().getDate();
  
      const theDateNow = this.theDate.getDate() + "/" + (this.theDate.getMonth() +1) + "/" + currentYear;
      const theHoursNow = new Date().getHours();
      const theMinutesNow = new Date().getMinutes();
      const theSecondsNow = new Date().getSeconds();
  
      const theTimeNow = theHoursNow + ":" + theMinutesNow + ":" + theSecondsNow;
  
  
      this._dateBook.DateName = theDateNow;
      this.api.MakeDate(this._dateBook).subscribe(res =>{
        
        this.dateId = res['DateID'];
        this._dateTimeBook.DateID = this.dateId;
        this.good2go1 = true;
        

          this._timeBook.TimeName = theTimeNow;
          this.api.MakeTime(this._timeBook).subscribe(res =>{
          
          this.timeId = res['TimeID'];
          this._dateTimeBook.TimeID = this.timeId;
          this.good2go = true;
          
          this.DoTheDate();
        });

      });*/


    
  }


}
