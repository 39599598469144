import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Booking } from '../booking';
import { DateBook } from '../dateBook';
import { TimeBook } from '../timeBook';
import { DateTimeBook } from '../dateTimeBook';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {ThemePalette} from '@angular/material/core';
import { User } from '../user';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-booking-checkout',
  templateUrl: './booking-checkout.component.html',
  styleUrls: ['./booking-checkout.component.scss']
})
export class BookingCheckoutComponent implements OnInit {

  constructor(private router : Router, private api : ApiService, private _snackBar: MatSnackBar, public sanitizer: DomSanitizer) { }

  dataSource : any;
  theStuff : any;
  theStuff1 : any;
  gotUser : any;
  currentUser :  User = new User();
  searchText;
  total;

  displayedColumns: string[] = ['id', 'description', 'dateCreated', 'type', 'slot', 'status', 'user'];

 userList : any;
 timeSlots : any;
 statusList : any;
 typeList : any;
 dateTimeList : any;
 datesList : any;
 timesList : any;
 img : any;
 ass : any;

 show1 = true;
 show2 = false;
 show3 = false;
 show4 = false;
 show5 = false;
 show6 = false;
 show7 = false;
 showImg = false;



 numList = 0;

 openSnackBar() {
   this._snackBar.open('You have cancelled your booking successfully.', 'OK', {
     duration: 5000,
     horizontalPosition: 'center',
     verticalPosition: 'bottom',
     
   });
 }

 moveAssess(){
   this.router.navigate(['myBookings']);
 }

 
 moveAssess1(){
    window.history.back();
}

showB2(){

  this.show1 = true;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;


  this.api.GetBookings2().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });

}

showB3(){

  this.show1 = false;
  this.show2 = true;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;

  this.api.GetBookings3().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });
  
}

showB4(){

  this.show1 = false;
  this.show2 = false;
  this.show3 = true;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;


  this.api.GetBookings4().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });
  
}

showB5(){

  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = true;
  this.show5 = false;
  this.show6 = false;
  this.show7 = false;


  this.api.GetBookings5().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });
  
}

showB7(){

  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = true;
  this.show6 = false;
  this.show7 = false;


  this.api.GetBookings7().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });
  
}

showB8(){

  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = true;
  this.show7 = false;

  this.api.GetBookings8().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });
  
}

showB9(){

  this.show1 = false;
  this.show2 = false;
  this.show3 = false;
  this.show4 = false;
  this.show5 = false;
  this.show6 = false;
  this.show7 = true;

  this.api.GetAssessments1().subscribe(res =>{
    this.theStuff = res;
    this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
  });
  
}

getAssessment(oo : any){
  this.api.ImgByAss(oo.AssessmentID).subscribe(res => {
    //this.img = this.sanitizer.bypassSecurityTrustResourceUrl(res[0].AssessmentImage) ;
    this.img = res[0].AssessmentImage;
    this.showImg =! this.showImg;
    this.api.GetAssessments2(oo.AssessmentID).subscribe(res => {
      this.ass = res[0];


    });
  });

}
 

 getUsers(){
   let x = localStorage.getItem('userSession');
   this.api.GetUser(x).subscribe(res => {
     let y = String(res['UserID']);
     localStorage.setItem('userId', y);
     this.gotUser = res;
   
     this.currentUser = this.gotUser;
     this.currentUser.UserID = this.gotUser.UserID;
     
     this.getItDone();

   });
 }

 getAllUsers(){
   this.api.getUserList().subscribe(res => {
     
     this.userList = res;
     this.getTimeSlot();
   });
 }

 getTimeSlot(){
   this.api.GetSlots().subscribe(res => {
     
     this.timeSlots = res;
     this.getBookingStatus();
   });
 }

 getBookingStatus(){
   this.api.GetBookingStatus().subscribe(res => {
   
     this.statusList = res;
     this.getBookingType();
   });
 }

 getBookingType(){
   this.api.GetBookingType().subscribe(res => {
     
     this.typeList = res;
     this.getDateTime();
   });
 }

 getDateTime(){
   this.api.GetDateTime().subscribe(res => {
    
     this.dateTimeList = res;
     this.getDate();
   });
 }

 getDate(){
   this.api.GetDates().subscribe(res => {
     
     this.datesList = res;
     this.getTime();
   });
 }

 getTime(){
   this.api.GetTimes().subscribe(res => {
     
     this.timesList = res;
   });
 }

 getItDone(){
   let u = Number(localStorage.getItem('userId'));
 
   this.api.GetBookings(u).subscribe(res => {
    

     this.getAllUsers();
     /*this.theStuff1 = new MatTableDataSource(this.theStuff);
     this.theStuff1.paginator = this.paginator;*/

   });
 }

 cancelBooking(xx : Booking){
   xx.BookingStatusID = 3;
  
   this.openSnackBar();
   this.api.UpdateBooking(xx).subscribe(res => {
     
     //this.toastr.success('You have cancelled your booking.', 'Booking Cancelled');
     window.location.reload();
   });
   
   //

 }


  ngOnInit(): void {

    
    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }

    this.getUsers();

    this.api.GetBookings2().subscribe(res =>{
    
      this.theStuff = res;
      this.total = this.theStuff.length * 40;
    this.numList = this.theStuff.length;
    });*/

  }



}
