export class User{
    UserID : number;
    Username : string;
    Password : string;
    UserSession : string;
    Email : string;
    Phone : string;
    PromoID : number;
    SpecialID : number;
    UserTypeID : number;
}