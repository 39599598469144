import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Booking } from '../booking';
import { DateBook } from '../dateBook';
import { TimeBook } from '../timeBook';
import { DateTimeBook } from '../dateTimeBook';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {ThemePalette} from '@angular/material/core';
import { User } from '../user';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';





@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss']
})



export class MyBookingsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;



  constructor(private router : Router, private api : ApiService, private _snackBar: MatSnackBar) { }


    dataSource : any;
   theStuff : any;
   theStuff1 : any;
   gotUser : any;
   currentUser :  User = new User();
   searchText;

   displayedColumns: string[] = ['id', 'description', 'dateCreated', 'type', 'slot', 'status', 'user'];

  userList : any;
  timeSlots : any;
  statusList : any;
  typeList : any;
  dateTimeList : any;
  datesList : any;
  timesList : any;

  numList = 0;

  openSnackBar() {
    this._snackBar.open('You have cancelled your booking successfully.', 'OK', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      
    });
  }

  moveAssess(){
    this.router.navigate(['home']);
  }
  

  getUsers(){
    let x = localStorage.getItem('userSession');
    this.api.GetUser(x).subscribe(res => {
      let y = String(res['UserID']);
      localStorage.setItem('userId', y);
      this.gotUser = res;
      this.currentUser = this.gotUser;
      this.currentUser.UserID = this.gotUser.UserID;
      this.getItDone();

    });
  }

  getAllUsers(){
    this.api.getUserList().subscribe(res => {
      this.userList = res;
      this.getTimeSlot();
    });
  }

  getTimeSlot(){
    this.api.GetSlots().subscribe(res => {
      this.timeSlots = res;
      this.getBookingStatus();
    });
  }

  getBookingStatus(){
    this.api.GetBookingStatus().subscribe(res => {
      this.statusList = res;
      this.getBookingType();
    });
  }

  getBookingType(){
    this.api.GetBookingType().subscribe(res => {
      this.typeList = res;
      this.getDateTime();
    });
  }

  getDateTime(){
    this.api.GetDateTime().subscribe(res => {
      this.dateTimeList = res;
      this.getDate();
    });
  }

  getDate(){
    this.api.GetDates().subscribe(res => {
      this.datesList = res;
      this.getTime();
    });
  }

  getTime(){
    this.api.GetTimes().subscribe(res => {
      this.timesList = res;
    });
  }

  getItDone(){
    let u = Number(localStorage.getItem('userId'));
    this.api.GetBookings(u).subscribe(res => {
      this.theStuff = res;
      this.numList = this.theStuff.length;
      this.getAllUsers();
      /*this.theStuff1 = new MatTableDataSource(this.theStuff);
      this.theStuff1.paginator = this.paginator;*/

    });
  }

  cancelBooking(xx : Booking){
    xx.BookingStatusID = 3;
    this.openSnackBar();
    this.api.UpdateBooking(xx).subscribe(res => {
      
      //this.toastr.success('You have cancelled your booking.', 'Booking Cancelled');
      window.location.reload();
    });
    
    //

  }

  ngOnInit(): void {

    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }
    
    this.getUsers();*/


    
  }

  

}
