<body>
  
    <div id="logo" style="padding-top: 60px; padding-bottom: 3%;">
          <h1 style="font-size: 40px;">Profile   <mat-icon>account_circle</mat-icon></h1>
    </div>

    <p>Update your information by entering the update information below.</p>
    <p>After entering your information, select the <b>Update</b> button.</p>
      
    <div class="target">
      <form (ngSubmit)="UpdateUser()">
  
        
          
      

      <div class="form-group">
         
        <div class="login"> 
            <h2>E-mail</h2>
          <mat-form-field appearance="outline">
            <mat-label>E-mail:</mat-label>
              <input matInput type="text" [(ngModel)]="currentUser.Email" placeholder="E-mail Address" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group">
        <div class="login">
            <h2>Phone</h2>
          <mat-form-field appearance="outline">
            <mat-label>Phone:</mat-label>
              <input matInput type="text" [(ngModel)]="currentUser.Phone" placeholder="Phone number" class="form-control" [ngModelOptions]="{standalone: true}" required>
          </mat-form-field>
        </div>
      </div>

 
    
    
        <div class="login">
          <button type="submit"  mat-raised-button  ><mat-icon>input</mat-icon> Update</button>&nbsp;
        </div>

      </form>

      <div class="login">
        <button   mat-raised-button (click)="Back2Home()"  >Back</button>
      </div>
    
    </div>

    <br/>
  <br/>
  <br/>

    <div *ngIf="showLoading" style="margin-top: 10%;">
      <h1 style="font-size: 60px;">Loading</h1>
        <mat-spinner id="spinz" ></mat-spinner>
    </div>

    <br/>
  <br/>
  <br/>

</body>
