import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from './user';
import { Booking } from './booking';
import { DateBook } from './dateBook';
import { DateTimeBook } from './dateTimeBook';
import { TimeBook } from './timeBook';
import { Assessment } from './assessment';
import { AssessmentImage } from './assessmentImage';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http : HttpClient) { }

  url = "https://localhost:44373/api/";


  Login(obj : User)
  {
    return this.http.post(this.url + "User/Login", obj)
    .pipe(map(result => result));
  }

  NewUser(obj : User)
  {
    return this.http.post(this.url + "User/NewUser", obj)
    .pipe(map(result => result));
  }

  GetSlots()
  {
    return this.http.get(this.url + "Booking_Slot")
    .pipe(map(result => result));
  }

  GetGenders()
  {
    return this.http.get(this.url + "Genders/GetGenders")
    .pipe(map(result => result));
  }

  GetBookingStatus()
  {
    return this.http.get(this.url + "Booking_Status")
    .pipe(map(result => result));
  }

  GetBookingType()
  {
    return this.http.get(this.url + "Booking_Type")
    .pipe(map(result => result));
  }

  GetDateTime()
  {
    return this.http.get(this.url + "Date_Time")
    .pipe(map(result => result));
  }

  GetDates()
  {
    return this.http.get(this.url + "Dates")
    .pipe(map(result => result));
  }

  GetTimes()
  {
    return this.http.get(this.url + "Times")
    .pipe(map(result => result));
  }

  GetBookings(obj : number)
  {
    return this.http.get(this.url + "Bookings/UserBookings?user=" + obj)
    .pipe(map(result => result)); 
  }

  GetBookings2()
  {
    return this.http.get(this.url + "Bookings/UserBookings2")
    .pipe(map(result => result));
  }

  GetBookings3()
  {
    return this.http.get(this.url + "Bookings/UserBookings3")
    .pipe(map(result => result));
  }

  GetBookings4()
  {
    return this.http.get(this.url + "Bookings/UserBookings4")
    .pipe(map(result => result));
  }

  GetBookings5()
  {
    return this.http.get(this.url + "Bookings/UserBookings5")
    .pipe(map(result => result));
  }

  GetBookings7()
  {
    return this.http.get(this.url + "Bookings/UserBookings7")
    .pipe(map(result => result));
  }

  GetBookings8()
  {
    return this.http.get(this.url + "Bookings/UserBookings8")
    .pipe(map(result => result));
  }



  GetBookings1()
  {
    return this.http.get(this.url + "Bookings")
    .pipe(map(result => result));
  }

  getUserList(){
    return this.http.get(this.url + "User/getUsers")
    .pipe(map(result => result));
  }



  MakeDate(obj : DateBook)
  {
    return this.http.post(this.url + "Bookings/CreateDate", obj)
    .pipe(map(result => result));
  }

  MakeTime(obj : TimeBook)
  {
    return this.http.post(this.url + "Bookings/CreateTime", obj)
    .pipe(map(result => result));
  }

  MakeDateTime(obj : DateTimeBook)
  {
    return this.http.post(this.url + "Bookings/CreateDateTime", obj)
    .pipe(map(result => result));
  }

  GetUser(obj : string)
  {
    return this.http.get(this.url + "User/getUser?text=" + obj )
    .pipe(map(result => result));
  }

  MakeBooking(obj : Booking)
  {
    return this.http.post(this.url + "Bookings/CreateBooking", obj)
    .pipe(map(result => result));
  }

  UpdateBooking(obj : Booking)
  {
    return this.http.put(this.url + "Bookings/UpdateBooking", obj)
    .pipe(map(result => result));
  }

  SendImage(obj : AssessmentImage)
  {
    return this.http.post(this.url + "Assessment_Image/SendImage", obj)
    .pipe(map(result => result));
  }

  ImgByAss(obj : number)
  {
    return this.http.get(this.url + "Assessment_Image/ImgByAss?num=" + obj)
    .pipe(map(result => result));
  }

   MakeAssessment(obj : Assessment)
  {
    return this.http.post(this.url + "Assessments/MakeAssessment", obj)
    .pipe(map(result => result));
  }

  GetAssessments(obj : number)
  {
    return this.http.get(this.url + "Assessment/RequestAssessments?userId=" + obj)
    .pipe(map(result => result));
  }

  GetAssessments2(obj : number)
  {
    return this.http.get(this.url + "Assessment/RequestAssessment1?assId=" + obj)
    .pipe(map(result => result));
  }

  GetAssessments1()
  {
    return this.http.get(this.url + "Assessment/RequestAssessment")
    .pipe(map(result => result));
  }

  GetAssessmentImages()
  {
    return this.http.get(this.url + "Assessment_Image")
    .pipe(map(result => result));
  }

  UpdateUser(obj : User)
  {
    return this.http.put(this.url + "User/UpdateUser", obj)
    .pipe(map(result => result));
  }

  CourierData(obj : number){
    return this.http.get(this.url + "Bookings/RequestDelivery?id=" + obj)
    .pipe(map(result => result));
  }

}
