import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { User } from '../user';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router : Router, private api : ApiService) { }

  _User: User = new User();
  newUser : any;

  showLoading = false;
  showNot = true;
  showError= false;


  hide = true;

  LoginUser(){
    this.router.navigate(["home"]);
    /*this.showLoadingPage();
    this.api.Login(this._User).subscribe(res => {
      this.newUser = res;
      if (!res["Error"]){
        localStorage.setItem('userSession', this.newUser.UserSession);
        this.router.navigate(["home"]);

      }
      else{
        if (res['Error']){
          this.showError = true;
          this.showLoadingPage();
        }
    
  
        
      }

    });*/
  }

  showLoadingPage(){
    this.showLoading = !this.showLoading;
    this.showNot = !this.showNot;
  }

  MoveCreateUser(){
    this.router.navigate(["createUser"]);
    console.log("moving...");
  }

  ngOnInit(): void {

    /*if (localStorage.getItem('userSession')){
      this.router.navigate(["home"]);
    }*/
  }

}

