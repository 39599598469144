<body>

    <div>
        <p style="font-size: 20px;" ><mat-icon>redeem</mat-icon>                  Book 3 Sessions and Get 1 <span>Free                       </span>   <mat-icon>redeem</mat-icon></p>
    </div>
        

    <div class="images" id="pstr1" >
        <div class="inImg" id="firstBaby" >
            <p> HIIT </p>
            <p> Cardio </p>
            <p> Training </p>
            <br>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Book </span></button>&nbsp;
        </div>
    </div>
    
    <div class="images" id="pstr2"> 
        <div class="inImg">
            <p> Private </p>
            <p> Training </p>
            <br>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Book </span></button>&nbsp;
        </div>
    </div>

    <div class="images" id="pstr3"> 
        <div class="inImg">
            <p> Yoga  </p> 
            <p> & </p>
            <p> Pilates </p>
            <br>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Book </span></button>&nbsp;
        </div>
    </div>

    <div class="images" id="pstr4"> 
        <div class="inImg">
            <p> Self </p>
            <p> Defence </p>
            <p> Class </p>
            <br>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Book </span></button>&nbsp;
        </div>
    </div>
    
    <div class="images" id="pstr5"> 
        <div class="inImg">
            <p> Diet </p>
            <p> & </p>
            <p> Nutrition </p>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Get Assessment </span></button>&nbsp;
        </div>
    </div>

    <div class="images" id="pstr6"> 
        <div class="inImg">
            <p> Building </p>
            <p> & </p>
            <p> Toning </p>
            <br>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Book </span></button>&nbsp;
        </div>
    </div>

    <div class="images" id="pstr7"> 
        <div class="inImg" id="lastBaby">
            <p> Zoom </p>
            <p> Session </p>
            <br>
            <button mat-raised-button class="button" style="vertical-align: middle;"><span>Book </span></button>&nbsp;
        </div>
    </div>

</body>