<body >
    <div class="login">
        <button mat-raised-button (click)="showB2()">Hiit Cardio Training </button>&nbsp;
        <button mat-raised-button (click)="showB3()">Private Training </button>&nbsp;
        <button mat-raised-button (click)="showB4()">Yoga & Pilates </button>&nbsp;
        <button mat-raised-button (click)="showB5()">Self Defence Class </button>&nbsp;
        <button mat-raised-button (click)="showB7()">Muscle Building & Toning </button>&nbsp;
        <button mat-raised-button (click)="showB8()">Zoom Session </button>&nbsp;
        <button mat-raised-button (click)="showB9()">Assessments</button>&nbsp;
    </div>

    <div *ngIf="show1">
            <div id="logo" >
             <h1>Hiit Cardio Training Bookings Management <mat-icon>manage_accounts</mat-icon></h1>
             <h2>Count: {{numList}}</h2>
            </div>
    
            <p>This is where you <b>Manage Hiit Cardio Training Bookings </b><mat-icon>manage_accounts</mat-icon> for Hiit City. </p>
            
    
            <div class="login">
                <p style="font-size: 30px;">Search</p>
              <mat-form-field style="width: 80%;" appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
              </mat-form-field>
            </div>
    
    
            <div id="tableDisplay" >
            <table >
               
                <tr>
                    
                    
                   <th>User</th> 
                   
                    <th  > Type Slot </th>
                    <th  > Status </th>
                    <th> Date Booked</th>
                    <th > Amount (incl. VAT)</th>
                </tr>
                <tr  *ngFor="let element of theStuff | filter:searchText ">
                    
                    
                        <td>{{element.Username}}</td>
                       
                    <td > {{element.BookingTypeName}} {{element.BookingSlotName}} </td>
                    <td  > {{element.BookingStatusName}} </td>
                    <td  > {{element.Date}} </td>
                    <td style="text-align: right;">    R40,00 </td>

    
                </tr>
                <tr *ngIf="numList == 0">
                    <td></td>
                    <td></td>
                    <td>No data to display.</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngIf="numList != 0">
                    <td colspan="4"></td>

                    <td><b>R {{total}},00</b></td>

                </tr>
            </table>
        </div>
    </div>  
    <div *ngIf="show2">

    
    
        <div id="logo" >
         <h1>Private Training Bookings Management <mat-icon>manage_accounts</mat-icon></h1>
         <h1>Count: {{numList}}</h1>
        </div>

        <p>This is where you <b>Manage Private Training Bookings </b><mat-icon>manage_accounts</mat-icon> for Hiit City. </p>
        

        <div class="login">
            <p style="font-size: 30px;">Search</p>
          <mat-form-field style="width: 80%;" appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
          </mat-form-field>
        </div>



        <div id="tableDisplay" >
        <table class="mat-elevation-z8">
            
            <tr>
                
                
                <th>User</th>
                

                <th  > Type Slot</th>
          
                <th  > Status </th>
            
                <th> Date Booked</th>
                <th > Amount (incl. VAT)</th>
            </tr>
            <tr  *ngFor="let element of theStuff | filter:searchText ">
                
                
                <td>{{element.Username}}</td>
             

                <td > {{element.BookingTypeName}}  {{element.BookingSlotName}} </td>
                <td  > {{element.BookingStatusName}} </td>
                <td  > {{element.Date}} </td>
                <td style="text-align: right;">    R40,00 </td>


            </tr>
            <tr *ngIf="numList == 0">
                
                <td colspan="5">No data to display.</td>
                
            </tr>
            <tr *ngIf="numList != 0">
                <td colspan="4"></td>

                <td><b>R {{total}},00</b></td>

            </tr>
        </table>
    </div>

    
</div>  
<div *ngIf="show3">

    
    
    <div id="logo" >
     <h1>Yoga & Pilates Bookings Management <mat-icon>manage_accounts</mat-icon></h1>
     <h1>Count: {{numList}}</h1>
    </div>

    <p>This is where you <b>Manage Yoga & Pilates Bookings </b><mat-icon>manage_accounts</mat-icon> for Hiit City. </p>
    

    <div class="login">
        <p style="font-size: 30px;">Search</p>
      <mat-form-field style="width: 80%;" appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
      </mat-form-field>
    </div>


    <div id="tableDisplay" >
    <table class="mat-elevation-z8">
        
        <tr>
            
            
            <th>User</th>
            

            <th  > Type Slot </th>
           
            <th  > Status </th>
        
            <th> Date Booked</th>
            <th > Amount (incl. VAT)</th>
        </tr>
        <tr  *ngFor="let element of theStuff | filter:searchText ">
            
            
            <td>{{element.Username}}</td>
        

            <td > {{element.BookingTypeName}}  {{element.BookingSlotName}}</td>
         
            <td  > {{element.BookingStatusName}} </td>
            <td  > {{element.Date}} </td>
            <td style="text-align: right;">    R40,00 </td>


        </tr>
        <tr *ngIf="numList == 0">
            <td colspan="5">No data to display.</td>

        </tr>
        <tr *ngIf="numList != 0">
            <td colspan="4"></td>

            <td><b>R {{total}},00</b></td>

        </tr>
    </table>
</div>


</div>  
<div *ngIf="show4">

    
    
    <div id="logo" >
     <h1>Self Defence Class Bookings Management <mat-icon>manage_accounts</mat-icon></h1>
     <h1>Count: {{numList}}</h1>
    </div>

    <p>This is where you <b>Manage Self Defence Class Bookings </b><mat-icon>manage_accounts</mat-icon> for Hiit City. </p>
    

    <div class="login">
        <p style="font-size: 30px;">Search</p>
      <mat-form-field style="width: 80%;" appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
      </mat-form-field>
    </div>


    <div id="tableDisplay" >
    <table class="mat-elevation-z8">
        
        <tr>
            
            
            <th>User</th>
           

            <th  > Type Slot</th>
           
            <th  > Status </th>
        
            <th> Date Booked</th>
            <th > Amount (incl. VAT)</th>
        </tr>
        <tr  *ngFor="let element of theStuff | filter:searchText ">
            
            
            <td>{{element.Username}}</td>
            
            <td > {{element.BookingTypeName}} {{element.BookingSlotName}}</td>
            
            <td  > {{element.BookingStatusName}} </td>
            <td  > {{element.Date}} </td>
            <td style="text-align: right;">    R40,00 </td>


        </tr>
        <tr *ngIf="numList == 0">
            <td colspan="5">No data to display.</td>

        </tr>
        <tr *ngIf="numList != 0">
            <td colspan="4"></td>

            <td><b>R {{total}},00</b></td>

        </tr>
    </table>
</div>


</div>  
<div *ngIf="show5">

    
    
    <div id="logo" >
     <h1>Muscle Building & Toning Bookings Management <mat-icon>manage_accounts</mat-icon></h1>
     <h1>Count: {{numList}}</h1>
    </div>

    <p>This is where you <b>Manage Muscle Building & Toning Bookings </b><mat-icon>manage_accounts</mat-icon> for Hiit City. </p>
    

    <div class="login">
        <p style="font-size: 30px;">Search</p>
      <mat-form-field style="width: 80%;" appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
      </mat-form-field>
    </div>


    <div id="tableDisplay" >
    <table class="mat-elevation-z8">
        
        <tr>
            
            
            <th>User</th>
           

            <th  > Type Slot</th>
            
            <th  > Status </th>
        
            <th> Date Booked</th>
            <th > Amount (incl. VAT)</th>
        </tr>
        <tr  *ngFor="let element of theStuff | filter:searchText ">
            
            
            <td>{{element.Username}}</td>
         

            <td > {{element.BookingTypeName}} {{element.BookingSlotName}}</td>
            
            <td  > {{element.BookingStatusName}} </td>
            <td  > {{element.Date}} </td>
            <td style="text-align: right;">    R40,00 </td>


        </tr>
        <tr *ngIf="numList == 0">
            <td colspan="5">No data to display.</td>

        </tr>
        <tr *ngIf="numList != 0">
            
            <td colspan="4"></td>

            <td><b>R {{total}},00</b></td>

        </tr>
    </table>
</div>

</div>  
<div *ngIf="show6">

    
    
    <div id="logo" >
     <h1>Zoom Session Bookings Management <mat-icon>manage_accounts</mat-icon></h1>
     <h1>Count: {{numList}}</h1>
    </div>

    <p>This is where you <b>Manage Zoom Session Bookings </b><mat-icon>manage_accounts</mat-icon> for Hiit City. </p>
    

    <div class="login">
        <p style="font-size: 30px;">Search</p>
      <mat-form-field style="width: 80%;" appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
      </mat-form-field>
    </div>


    <div id="tableDisplay" >
    <table class="mat-elevation-z8">
       
        <tr>
            
            
            <th>User</th>
            

            <th  > Type Slot </th>
            
            <th  > Status </th>
        
            <th> Date Booked</th>
            <th > Amount (incl. VAT)</th>
        </tr>
        <tr  *ngFor="let element of theStuff | filter:searchText ">
            
            
            <td>{{element.Username}}</td>
            

            <td > {{element.BookingTypeName}}  {{element.BookingSlotName}} </td>
            
            <td  > {{element.BookingStatusName}} </td>
            <td  > {{element.Date}} </td>
            <td style="text-align: right;">    R40,00 </td>


        </tr>
        <tr *ngIf="numList == 0">
            <td colspan="5">No data to display.</td>

        </tr>
        <tr *ngIf="numList != 0">
        
            <td colspan="4"></td>
            
            <td><b>R {{total}},00</b></td>

        </tr>
    </table>
</div>


</div>  

<div *ngIf="show7">

    
    
    <div id="logo" >
     <h1>Assessments <mat-icon>description</mat-icon></h1>
     <h1>Count: {{numList}}</h1>
    </div>

    <p>This is where you <b>Manage Assessments</b> <mat-icon>description</mat-icon> for Hiit City.</p>
    


    <div class="form-group">
        <div class="login">
            <p style="font-size: 30px;">Search</p>
          <mat-form-field style="width: 80%;" appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="searchText" placeholder="Enter search keywords." class="form-control" [ngModelOptions]="{standalone: true}" >
          </mat-form-field>
        </div>
      </div>


    <div id="tableDisplay" class="mat-elevation-z8">
        <table >
            <tr>
                <th>User</th>
                <th  > Goals </th>
                <th  > Date Created </th>
                
                <th> Action </th>

            </tr>   
            <tr *ngIf="numList == 0">
                <td colspan="5">No data to display.</td>

            </tr>
            <tr *ngFor="let element of theStuff | filter:searchText">
                <td>{{element.Username}}</td>
                <td  > {{element.Goal}} </td>
                <td > {{element.AssessmentDateCreated}}</td>
               
                <td>    <button mat-icon-button aria-labelledby="View image" class="deleteIcon" (click)="getAssessment(element)" ><mat-icon  >visibility</mat-icon></button></td>
                
                

            </tr>
        </table>
    </div>
    <div class="loginGroup" *ngIf="showImg">
        <h2><u>Image:</u></h2>
        <img id="disImg"  [src]="sanitizer.bypassSecurityTrustUrl(img)" alt="imageShow" >

        <h2><u>Age:</u></h2>
        <p>{{ass.AssessmentAge}} yrs</p>

        <h2><u>Height:</u></h2>
        <p>{{ass.AssessmentHeight}} m</p>

        <h2><u>Current Weight:</u></h2>
        <p>{{ass.AssessmentWeight}} kg</p>

        <h2><u>Target weight</u></h2>
        <p>{{ass.AssessmentTargetWeight}} kg</p>

        <h2><u>Allergies / Food dislikes:</u></h2>
        <p>{{ass.AssessmentAllergies}}</p>

        <h2><u>Healthy favourite foods:</u></h2>
        <p>{{ass.AssessmentHealthy}}</p>

        <h2><u>Daily foods:</u></h2>
        <p>{{ass.AssessmentDaily}}</p>

        <h2><u>Injuries:</u></h2>
        <p>{{ass.AssessmentInjury}}</p>

        <h2><u>Medical conditions:</u></h2>
        <p>{{ass.AssessmentMedical}}</p>

        <h2><u>Hours of sleep (avg.):</u></h2>
        <p>{{ass.AssessmentSleep}} hr(s)</p>

        <h2><u>Flexibility in scheduling:</u></h2>
        <p>{{ass.AssessmentFlexibility}}</p>

        <h2><u>Training history:</u></h2>
        <p>{{ass.AssessmentTraining}}</p>

        <h2><u>Contact information</u></h2>
        <p>{{ass.Contact}}</p>
    </div>


</div>  

</body>