import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateUserComponent } from './create-user/create-user.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { BookingComponent } from './booking/booking.component';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { MyAssessmentsComponent } from './my-assessments/my-assessments.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { BookingCheckoutComponent } from './booking-checkout/booking-checkout.component';



const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'createUser', component: CreateUserComponent},
  {path: 'home', component: HomeComponent },
  {path: 'booking', component: BookingComponent },
  {path: 'myBooking', component: MyBookingsComponent},
  {path: 'assessment', component: AssessmentComponent},
  {path: 'myAssessment', component: MyAssessmentsComponent},
  {path: 'myProfile', component: MyProfileComponent},
  {path: 'basket', component: BookingCheckoutComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
