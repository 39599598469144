<body style="background-color: white; margin: 0px; padding: 0px;">


  <!--<div id="logo">
      <img id="imgT" src="assets/img1.jpg" >
  </div>-->
    
  <div id="logo">
        <h1 style="font-size: 40px; padding-bottom: 30px; padding-top: 30px;">Welcome</h1>
  </div>

  
    
  <div *ngIf="showNot" class="target">
    <form #loginForm="ngForm" (ngSubmit)="LoginUser()">

      
        
    <div class="form-group">
      <div class="login">
        <h2>Username</h2>
        <mat-form-field appearance="outline" class="dataFull">
          <mat-label >Username</mat-label>
          <input  matInput [(ngModel)]="_User.Username" placeholder="Enter your username." class="form-control" [ngModelOptions]="{standalone: true}" required>
        </mat-form-field>
      </div>
    </div>
    
    <div class="form-group">
      <div class="login">
        <h2>Password</h2>
        <mat-form-field appearance="outline" class="dataFull">
          <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="_User.Password" [type]="hide ? 'password' : 'text'" placeholder="Enter your password" class="form-control" [ngModelOptions]="{standalone: true}" required>
            <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="showError" style="background-color: red; text-align: center; width: 60%; padding: 10px; color: white; font-size: 12px;">
      <h2><mat-icon>error</mat-icon>        Please enter a valid username and password.</h2>
    </div> 
  
      <div class="login">
        <button type="submit"  mat-raised-button  ><mat-icon>login</mat-icon>          Log In</button>&nbsp;
      </div>
    </form>

    

  </div>

  
  <br/>
  <br/>
  <br/>

  <div *ngIf="showNot" class="target">
      <h2>Not a member? <button type="button" mat-raised-button (click)="MoveCreateUser()" ><mat-icon>how_to_reg</mat-icon>          Sign Up</button> for free and get the 1st booking on us.</h2>&nbsp;
  </div>

  <br/>
  <br/>
  <br/>

  <div *ngIf="showLoading" style="padding-top: 10%;">
    <h1 style="font-size: 60px;">Loading</h1>
      <mat-spinner  id="spinz"></mat-spinner>
  </div>

  <br/>
  <br/>
  <br/>

</body>