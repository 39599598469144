import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit(): void {
  }

  moveInsta(){
    window.open("https://www.instagram.com/thebeam_support/", "_blank");
  }

  moveFacebook(){
    window.open("https://www.facebook.com/pages/category/Health-Beauty/HIIT-City-102749214946804/", "_blank");
  }

  moveYouTube(){
    window.open("https://www.youtube.com/channel/UCHZubFXAJJZbeN5cPWGQ6Vg", "_blank");
  }

  moveX(){
    window.open("https://x.com/TheBeam_Support", "_blank");
  }

}
