import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { User } from '../user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router : Router,  private api : ApiService) { }

  currentUser : any;

  ngOnInit(): void {
    /*if (!localStorage.getItem('userSession')){
      this.router.navigate(['']);
    }

    

    this.getUsers();*/
 
  }

  getUsers(){
    let x = localStorage.getItem('userSession');
    this.api.GetUser(x).subscribe(res => {
      this.currentUser = res;
      localStorage.setItem('userId', this.currentUser.UserID);
    });
  }

  moveBooking1(){
    let xx = '2';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveBooking2(){
    let xx = '3';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveBooking3(){
    let xx = '4';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveBooking4(){
    let xx = '5';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveBooking5(){
    let xx = '6';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveBooking6(){
    let xx = '7';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveBooking7(){
    let xx = '8';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['booking']);
  }

  moveAssessment(){
    let xx = '6';
    localStorage.setItem('bookingType', xx)
    this.router.navigate(['assessment']);
  }

}
