export class Assessment{
    AssessmentID : number;
    GenderID : number;
    AssessmentAge : number;
    AssessmentHeight : number;
    AssessmentWeight : number;
    AssessmentTargetWeight : number;
    AssessmentMedical : string;
    AssessmentGoal : string;
    AssessmentAllergies : string;
    AssessmentHealthy : string;
    AssessmentDaily : string;
    AssessmentInjury : string;
    AssessmentTraining : string;
    AssessmentFlexibility : string;
    AssessmentSleep : number;
    UserID : number;
    DateTimeID : number;
    AssessmentDateCreated : string;
}