
<ul>
    <li class="dropdown">
        <!--<a href="javascript:void(0)" class="dropbtn">Menu</a>-->
        <div>
            <mat-icon id="menuDiv">menu</mat-icon>
        </div>
        <div class="dropdown-content">
            <a href=""><mat-icon>home</mat-icon> Home</a>
            <a (click)="myBook()"><mat-icon>date_range</mat-icon> My Bookings</a>
            <a  (click)="myAss()"><mat-icon>description</mat-icon> My Assessments</a>
            <a  (click)="myPro()"><mat-icon>account_circle</mat-icon> My Profile</a>
            <a *ngIf="show"><mat-icon>manage_accounts</mat-icon>Management</a>
            <a (click)="LogOut()"><mat-icon>logout</mat-icon> Log Out</a>
        </div>
    </li>
    <li  id="cent" >
        <div >
            Fitness Booking
        </div>
    </li>
    <!--<li id="navBar">
        <div id="logo" (click)="refresh()">
            <img src="/assets/img1.jpg" id="photo">
        </div>
    </li>-->
</ul>