<div id="footer">
    <div class="logo" style="padding-top: 50px; padding-bottom: 300px;" >
        <h1><mat-icon>room</mat-icon><u>Location</u> </h1>
        <h1>  100 Hundred Street, </h1>
        <h1>  Pretoria,</h1>
        <h1>  South Africa</h1>
<br>
<br>
        <h1><mat-icon>alarm</mat-icon><u>Operating Times</u> </h1>
        <h1>  Monday - Sunday</h1>
        <h1>  9 AM - 3 PM</h1>
    </div>
    <div class="logo" >
        <img (click)="moveX()" src="/assets/free-twitter-x.png" id="photo"  width="90px" height="90px">
        <br>    
        X
    </div>
    <br>
    <br>
    <br>
    <div class="logo"  >
        <img (click)="moveInsta()"  src="/assets/instagram.ico" id="photo"  width="50px" height="50px">
        <br>
        <br>
        Instagram
    </div>
    <br>
    <div class="logo" style="padding-bottom: 1px;">
        <a style="text-align: center;  font-size: 20px;">Developed by <a href="http://thebeam.co.za">The Beam™</a></a>
    </div>

</div>